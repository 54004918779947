import classes from "./Slideshow.module.scss"
import Container from "./Container";
import Heading from "../components/Heading";
import AnimateIn from "../components/AnimateIn";


import diver from "../../imgs/diver.png"
import slide1 from "../../imgs/slide1.png";
import slide2 from "../../imgs/slide2.png";
import slide3 from "../../imgs/slide3.png";
import footer from "../../imgs/slideshow-footer.svg";

import {useState} from "react";

const Slideshow = () => {

    const [activeSlide, setActiveSlide] = useState(0);
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance

        if(isLeftSwipe) {
            setActiveSlide(prevState => {
                if(prevState + 1 === slides.length){
                    return 0
                } else {
                    return prevState + 1
                }
            })
        }

        if(isRightSwipe) {
            setActiveSlide(prevState => {
                if(prevState - 1 < 0){
                    return slides.length - 1
                } else {
                    return prevState - 1
                }
            })
        }

    }

    const slides = [slide1, slide2, slide3]

    return (
        <div className={classes.section}>

            <div className={classes.diver}>
                <AnimateIn type="diver" delay={500} offset={300}>
                    <img src={diver} alt=""/>
                </AnimateIn>

            </div>

            <Container>


                <Heading>
                    <h2>A peek at what’s to come</h2>
                </Heading>
            </Container>


            <div className={classes.slides}>
                {slides.map((el, i) => {

                    const slideClass = [classes.slide]

                    if(i === activeSlide) {
                        slideClass.push(classes.active)
                    }

                    // If prev in array
                    // Or if last in array and first item is active
                    if(
                        (i === activeSlide - 1) ||
                        (i + 1 === slides.length && activeSlide === 0)
                    ){
                        slideClass.push(classes.prev)
                    }

                    // If next in array
                    // Or if first in array and final item is active
                    if(
                        (i === activeSlide + 1) ||
                        (i === 0 && slides.length === activeSlide + 1)
                    ){
                        slideClass.push(classes.next)
                    }


                    return <img
                        key={i}
                        src={el}
                        className={slideClass.join(" ")}
                        onClick={()=>setActiveSlide(i)}
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                        alt=""/>
                })}
            </div>

            <div className={classes.controls}>
                {slides.map((_, i) => {

                    const circleClasses = [classes.circle]

                    if(activeSlide === i) {
                        circleClasses.push(classes.circleActive)
                    }


                    return <div
                        key={i}
                        className={circleClasses.join(" ")}
                        onClick={()=>setActiveSlide(i)}
                    />
                })}
            </div>


            <div className={classes.footer}>
                <img src={footer} alt=""/>
            </div>
        </div>
    )
}

export default Slideshow
