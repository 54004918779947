import React, {useEffect, useState} from "react";
import {getData} from "../api/Helpers";
import Spinner from "./Spinner";
import classes from "./ProfileStories.module.scss"
import ProfileStory from "./ProfileStory";

const ProfileStories = props => {


    const [results, setResults] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const params = {
            author_id: props.author,
        }

        const controller = new AbortController();
        getData('/get-profile-stories/', controller, params)
            .then(result => setResults(result))
            .catch(() => setError(true))
            .finally(() => setLoading(false))
        return () => controller.abort()
    },[props.author])



    return (
        <div className={classes.div}>
            {results.length > 0 && <ul>{results.map(story => <ProfileStory key={story.id} data={story}/>)}</ul>}
            {loading && <Spinner />}
            {error && <p>Something went wrong</p>}
        </div>
    )

}

export default ProfileStories
