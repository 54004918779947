import React, {useRef} from "react";
import classes from "./ProfileSummary.module.scss";
import Badge from "./Badge";
import star from ".././../imgs/star.svg"
import logoHolder from ".././../imgs/profile-logo.svg"
import Button from "./Button";
//import Utils from "../Utils";

const ProfileSummary = props => {

    const div = useRef();

    const founder = props.data.founder ? <img src={star} alt="founder" className={classes.star}/> : ""
    const profileLogo = props.data.logo ? props.data.logo : logoHolder

    const animate = () => {
        const rect = div.current.getBoundingClientRect();

        props.animate({
            top: rect.top,
            left: rect.left,
            width: div.current.offsetWidth,
            height: div.current.offsetHeight
        })
    }

    // const createCircle = (size, color) => ({
    //     height: size,
    //     width: size,
    //     borderRadius: '50%',
    //     backgroundColor: color
    // })
    //
    // const createSquare = (size, color) => ({
    //     height: size,
    //     width: size,
    //     backgroundColor: color
    // })
    //
    // const createTriangle = (size, color) => ({
    //     height: 0,
    //     //width: size,
    //     borderLeft: size/2 + 'px solid transparent',
    //     borderRight: size/2 + 'px solid transparent',
    //     borderTop: (size * (Math.sqrt(3)/2)) + 'px solid ' + color,
    // })

    // const creatSticker = (placement = 'left') => {
    //
    //     const SecondPlacement = placement === 'left' || placement === 'right' ? 'top' : 'left'
    //
    //     const stickerSize = Utils.getRandomInt(20,80);
    //
    //     const colors = ['#FBB9C5', '#FDD0B1', '#F9EFC7','#C3EDBF', '#B8DFE6', '#C5BBDE'];
    //     const color = Utils.getRandomInt(0,colors.length)
    //
    //     const types = [createCircle, createTriangle, createSquare]
    //     const type = Utils.getRandomInt(0,types.length)
    //
    //     const stickerStyles = {
    //         [placement]: -Utils.getRandomInt(20,40) + 'px',
    //         [SecondPlacement]: Utils.getRandomInt(0,100) + '%',
    //         zIndex: stickerSize > 30 ? 1 : 3,
    //         transform: 'rotate('+Utils.getRandomInt(0,360)+'deg)'
    //     }
    //
    //     return {
    //         ...stickerStyles,
    //         ...types[type](stickerSize, colors[color])
    //     }
    // }

    // const sticker1 = useRef(creatSticker())
    // const sticker2 = useRef(creatSticker('right'))
    // const sticker3 = useRef(creatSticker())
    // const sticker4 = useRef(creatSticker('right'))
    // const sticker5 = useRef(creatSticker())
    // const sticker6 = useRef(creatSticker('right'))
    // const sticker7 = useRef(creatSticker('top'))
    // const sticker8 = useRef(creatSticker('bottom'))
    // const sticker9 = useRef(creatSticker('top'))
    // const sticker10 = useRef(creatSticker('bottom'))


    return (
        <React.Fragment>
            <div className={classes.div} ref={div}>

                {/*<div className={classes.sticker} style={sticker1.current}/>*/}
                {/*<div className={classes.sticker} style={sticker2.current}/>*/}
                {/*<div className={classes.sticker} style={sticker3.current}/>*/}
                {/*<div className={classes.sticker} style={sticker4.current}/>*/}
                {/*<div className={classes.sticker} style={sticker5.current}/>*/}
                {/*<div className={classes.sticker} style={sticker6.current}/>*/}
                {/*<div className={classes.sticker} style={sticker7.current}/>*/}
                {/*<div className={classes.sticker} style={sticker8.current}/>*/}
                {/*<div className={classes.sticker} style={sticker9.current}/>*/}
                {/*<div className={classes.sticker} style={sticker10.current}/>*/}

                <div className={classes.inner}>
                    <div className={classes.header}>
                        <img src={profileLogo} className={classes.logo} alt={props.data.name}/>
                        <h4>{props.data.name}</h4>
                        {founder}

                        <Badge square>{props.data.type}</Badge>
                    </div>

                    <p>{props.data.description}</p>

                    <h5>Services</h5>
                    <ul>
                        {props.data.services.map((el,i) => <li key={i}><Badge>{el}</Badge></li>)}
                    </ul>

                    <div className={classes.button}>
                        <Button url={'/profile/' + props.data.url} callback={animate} pageChange w100>
                            View profile
                        </Button>
                    </div>
                </div>



            </div>
        </React.Fragment>

    )
}

export default ProfileSummary
