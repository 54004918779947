import Utils from "../../Utils";
import SearchFilterContainer from "./SearchFilterContainer";
import SearchFilterLocationPopup from "./SearchFilterLocationPopup";

const SearchFilterLocation = props => {

    const selectedText = props.data.locationName + ' +' + (props.data.radius || Utils.defaultRadius) + 'mi' || 'Unknown'
    const noSelection = props.data.locationName === undefined || props.data.locationName === ""

    const clearAll = () => {
        props.setSearchPending(true)
        props.setSearchData(prevState=>({
            ...prevState,
            lat: undefined,
            lng: undefined,
            radius : undefined,
            locationName : ''
        }))
    }

    return (
        <SearchFilterContainer
            defaultTitle="Any location"
            popUpTitle="Location"
            selectedText={selectedText}
            noSelection={noSelection}
            toggleOpen={props.toggleOpen}
            open={props.open}
            clearAll={clearAll}
            filtersLoading={props.filtersLoading}
        >
            <SearchFilterLocationPopup
                popUpContent={Utils.servicesList}
                onChange={props.setSearchData}
                setSearchPending={props.setSearchPending}
                data={props.data}
                setFiltersLoading={props.setFiltersLoading}
            />
        </SearchFilterContainer>
    )
}

export default SearchFilterLocation
