import React, {useRef, useEffect} from "react";
import {useStore} from "../store/store"

const CheckOnScreen = props => {

    const [state] = useStore();
    //const [onScreen, setOnScreen] = useState(false);
    const div = useRef();


    // Set refs for useEffect
    const divOnscreen = useRef(false);
    const offset = useRef(props.offset || 0)
    const top = useRef(props.top || 0)

    // Create function refs
    const onPageCallback = props.OnPage ? props.OnPage : null
    const offPageCallback = props.OffPage ? props.OffPage : null

    useEffect(()=>{



        //const scroll = state.windowScroll || 0;
        const withWindowHeight = top.current ? 0 : window.innerHeight;


        // if(props.test){
        //     console.log(div.current.getBoundingClientRect().y)
        // }



        if(div.current.getBoundingClientRect().y - withWindowHeight + offset.current <= 0) {

            //If already on screen don't run callback
            //if(onScreen) return
            if(divOnscreen.current) return



            if(onPageCallback){
                onPageCallback()
            }

            //setOnScreen(true)
            divOnscreen.current = true
        } else {



            //If already off-screen don't run callback
            //if(!onScreen) return
            if(!divOnscreen.current) return

            if(offPageCallback){
                offPageCallback()
            }

            //setOnScreen(false)
            divOnscreen.current = false
        }

    },[state.windowScroll, onPageCallback, offPageCallback])

    return (
        <div ref={div}>
            {props.children}
        </div>
    )

}

export default CheckOnScreen;
