import Container from "./Container";
import Heading from "../components/Heading";
import classes from "./Hero.module.scss"

import Button from "../components/Button";
import AnimateIn from "../components/AnimateIn";


import footer from "../../imgs/hero-footer.svg"
import jump from "../../imgs/jump.png"
import jumpIn from "../../imgs/jump-in.svg"


const Hero = props => (
    <div className={classes.hero}>

        <Container>

            <div className={classes.inner}>
                <div className={classes.heading}>
                    <div>
                        <Heading>
                            <h1><span>West Wales Media</span><br/> coming soon</h1>
                        </Heading>
                        <AnimateIn type="fadeUp" delay="600">
                            <Button
                                url="/onboarding"
                                pageChange
                                btnStyle="primary"
                                big
                                callback={props.changePage}
                                arrow>Keep me updated</Button>
                        </AnimateIn>

                    </div>

                </div>


                <div className={classes.jump}>
                    <img src={jump} alt="jump" width={1200} height={1060}/>
                </div>

                <div className={classes.jumpText}>
                    <img src={jumpIn} alt="jump in"/>
                </div>
            </div>



        </Container>

        <div className={classes.footer}>
            <img src={footer} alt=""/>
        </div>
    </div>
)

export default Hero
