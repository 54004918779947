import classes from "./ProfileStory.module.scss"
import thumbnail from "../../imgs/thumbnail.svg"

const ProfileStory = props => {

    const image = props.data.thumbnail ? props.data.thumbnail : thumbnail

    return (
        <li className={classes.story}>
            <img src={image} alt={props.data.title} />

            <div className={classes.detail}>
                <h4>{props.data.title}</h4>
                <p>Suspendisse sit amet est iaculis, eleifend mauris non, rhoncus lectus.</p>
                <p>Read story</p>
            </div>
        </li>
    )
}

export default ProfileStory
