import classes from "./Badge.module.scss"
import closeImg from "../../imgs/close-white.svg"
import Utils from "../Utils";

const Badge = props => {

    const classList = [classes.span]

    if(props.square){
        classList.push(classes.square)
    }

    if(props.cursor){
        classList.push(classes.cursor)
    }

    if(props.primary) {
        classList.push(classes.primary)
    }

    if((
        props.children === 'Agency' ||
        props.children === 'Consultant' ||
        props.children === 'Freelancer'
    ) && !props.cursor){
        classList.push(classes[props.children])
    }



    if(props.closeCallback){
        classList.push(classes.withClose)
    }

    const mainClicked = () =>{
        if(props.callback){
            props.callback()
        }
    }

    const closeClicked = () => {
        if(props.closeCallback){
            props.closeCallback()
        }
    }

    const clippedText = Utils.cropString(props.children,20)

    return (
        <span className={classes.outer}>
            <span className={classList.join(" ")} onClick={mainClicked} >{clippedText}</span>
            {props.closeCallback && <span onClick={closeClicked} className={classes.closeBtn}>
                <img src={closeImg} alt="close"/>
            </span>}
        </span>

    )
}

export default Badge
