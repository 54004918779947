import classes from "./PageChange.module.scss"

const PageChange = props => {

    const classList = [classes.div]


    return <div className={classList.join(" ")}/>
}

export default PageChange
