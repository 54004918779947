import classes from "./SearchFilterList.module.scss";
import ButtonToggle from "../ButtonToggle";

const SearchFilterList = props => (
    <ul className={classes.list}>
        {props.popUpContent.map((el, i) => {
            const selected = props.data.includes(el)

            return (
                <li key={i}>
                    <ButtonToggle
                        selected={selected}
                        callback={()=>props.onChange(el, !selected)}>
                        {el}
                    </ButtonToggle>
                </li>
            )
        })}
    </ul>
)
export default SearchFilterList
