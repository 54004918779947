import closeImg from "../../../imgs/close.svg"
import classes from "./SearchFilterContainer.module.scss"
import Spinner from "../Spinner";
import Badge from "../Badge";

const SearchFilterContainer = props => {

    const classList = [classes.popUp]

    if(props.open){
        classList.push(classes.popUpOpen)
    }


    const filterTitle = props.noSelection ?
        <span className={classes.initial} onClick={()=>props.toggleOpen(true)}>{props.defaultTitle}</span> :
        <Badge
            square
            cursor
            primary
            callback={()=>props.toggleOpen(true)}
            closeCallback={props.clearAll}
        >
            {props.selectedText}
        </Badge>


    return (
        <div className={classes.div}>

            <div className={classes.title}>
                {filterTitle}
            </div>

            <div className={classList.join(" ")}>

                {props.filtersLoading && <div className={classes.searchBox}><Spinner/></div>}

                <header>
                    <span>{props.popUpTitle}</span>
                    <img src={closeImg} alt="close" onClick={()=>props.toggleOpen(false)}/>
                </header>

                <div className={classes.list}>
                    {props.children}
                </div>


            </div>

        </div>
    )
}
export default SearchFilterContainer
