import React, {useState} from "react";
import classes from "./AnimateIn.module.scss";
import CheckOnScreen from "./CheckOnScreen";

const AnimateIn = props => {

    const [onScreen, setOnScreen] = useState(false);

    const divClass = [classes[props.type]];

    //Delays
    const delay = props.delay ?  props.delay : 0;
    //const delayAdj = scroll < 1 && onScreen ? delay + 300 : delay;

    const offset = props.offset ? props.offset : 0;

    if(onScreen) {
        divClass.push(classes[props.type + "Active"])
    }



    return (
        <CheckOnScreen OnPage={()=>setOnScreen(true)} offset={offset}>
            <div className={divClass.join(" ")} style={{
                transitionDelay: delay + 'ms'
            }}>
                <div className={classes.innerBox} style={{
                    transitionDelay: delay + 'ms'
                }}>
                    {props.children}
                </div>
            </div>
        </CheckOnScreen>

    )
}

export default AnimateIn
