import React from "react";
import classes from "./SearchBox.module.scss"
import Container from "./Container";
import Button from "../components/Button";
import SearchFilterType from "../components/SearchFilter/SearchFilterType";
import SearchFilterServices from "../components/SearchFilter/SearchFilterServices";
import SearchFilterLocation from "../components/SearchFilter/SearchFilterLocation";

const SearchBox = props => {

    const toggleOpen = (filter , open) => {
        props.setFiltersOpen(prevState => ({
            ...prevState,
            [filter]: open
        }))
    }

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Container>
                    <div className={classes.inner}>
                        <div className={classes.item}>
                            <div className={classes.title}>
                                <h2>Find a business</h2>
                            </div>

                        </div>

                        <div className={classes.item}>
                            <SearchFilterType
                                data={props.searchData.type}
                                open={props.filtersOpen.type}
                                toggleOpen={open=>toggleOpen('type', open)}
                                setSearchData={props.setSearchData}
                                setSearchPending={props.setSearchPending}
                                //filtersLoading={props.filtersLoading.type}
                            />
                        </div>

                        <div className={classes.item}>
                            <SearchFilterLocation
                                data={props.searchData}
                                open={props.filtersOpen.location}
                                toggleOpen={open=>toggleOpen('location',open)}
                                setSearchData={props.setSearchData}
                                setSearchPending={props.setSearchPending}
                                setFiltersLoading={loading=>props.setFiltersLoading('location',loading)}
                                filtersLoading={props.filtersLoading.location}
                            />
                        </div>

                        <div className={classes.item}>
                            <SearchFilterServices
                                data={props.searchData.services}
                                open={props.filtersOpen.services}
                                toggleOpen={open=>toggleOpen('services', open)}
                                setSearchData={props.setSearchData}
                                setSearchPending={props.setSearchPending}
                                //filtersLoading={props.filtersLoading.services}
                            />
                        </div>

                        <div className={classes.item}>
                            <div className={classes.searchBtn}>
                                <Button
                                    w100
                                    arrow
                                    btnStyle="primary"
                                    callback={props.runSearch}
                                    disabled={!props.searchPending || props.loading}
                                >
                                    Update
                                </Button>
                            </div>

                        </div>

                    </div>
                </Container>
            </div>
            <div className={classes.padding}/>
        </React.Fragment>

    )
}

export default SearchBox
