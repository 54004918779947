
import React, {useState} from "react";

import classes from "./OnboardingForm.module.scss"
import OnboardingFormStage from "./OnboardingFormStage";
import Button from "../components/Button";

const OnboardingForm = props => {

    const [currentStage, setCurrentStage] = useState(1)
    const [goingBack, setGoingBack] = useState(false)
    const [formHeight, setFormHeight] = useState(500)


    const stages = {
        1: {
            title: 'First of all, could we get your name please?',
            button: 'Next',
            fields : [
                {
                    name: 'app_name',
                    type: 'text',
                    placeholder: 'Your name. Right here.',
                }
            ]
        },
        2: {
            title: 'What do you want to do today?',
            button: 'Next',
            fields : [
                {
                    name: 'looking',
                    type: 'multi',
                    options: [
                        'I\'m looking for marketing or creative services, advice and updates. Let me know when West Wales Media launches.',
                        'I offer marketing or creative services and want to join the West Wales Media directory. Sign me up!'
                    ]
                }
            ]
        },
        3: {
            title: 'Cool! What\'s the name of your business?',
            button: 'Next',
            fields : [
                {
                    name: 'business',
                    type: 'text',
                    placeholder: 'You know what to do',
                }
            ]
        },
        4: {
            title: 'Last one and we\'re done. Could we get your email so we can send you updates about and news about West Wales Media?',
            button: 'Sign up',
            fields : [
                {
                    name: 'email',
                    type: 'email',
                    placeholder: 'youremail@here.co',
                }
            ]
        }
    }


    const updateField = e => {
        props.setData(prev => ({
            ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const updateMulti = (id, text) => {
        props.setData(prev => ({
            ...prev,
            [id]: text
        }))
    }

    const nextStep = () => {

        // Let system know it's going back
        setGoingBack(false)

        const storeCurrent = currentStage;

        // Set to -1 to hide all
        setCurrentStage(-storeCurrent)

        // If at last stage submit
        if(Object.keys(stages).length === storeCurrent) {
            props.submit()
        } else {
            setTimeout(()=> {
                window.scrollTo(0, 0);
                setCurrentStage(storeCurrent + 1);
            }, 500);
        }

    }

    const prevStep = () => {

        // Let system know it's going back
        setGoingBack(true)

        const storeCurrent = currentStage;

        // Set to -1 to hide all
        setCurrentStage(-storeCurrent)

        setTimeout(()=> {
            window.scrollTo(0, 0);
            setCurrentStage(storeCurrent -1);
        }, 500);
    }

    return (
        <div className={classes.div} style={{height: props.errors.length ? "auto" : formHeight}}>

            {Object.entries(stages).map(([stage_id, stage]) => {

                const id = parseInt(stage_id)

                return (
                    <div key={stage_id}>
                        <OnboardingFormStage
                            stage_id={stage_id}
                            stage={stage}
                            data={props.data}
                            errors={props.errors}
                            updateField={updateField}
                            updateMulti={updateMulti}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            hide={currentStage === -id}
                            show={currentStage === id}
                            goingBack={goingBack}
                            setFormHeight={setFormHeight}
                        />
                    </div>
                )
            })}

            {props.errors.length > 0 && <Button
                callback={props.submit}
                btnStyle="primary"
                w100
                arrow
            >
                Try again
            </Button>}

        </div>
    )
}

export default OnboardingForm
