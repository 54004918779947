import React, {Suspense, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import Spinner from "./js/components/Spinner";

//Pages
import Search from "./js/routes/Search";
import Profile from "./js/routes/Profile";
import Holding from "./js/routes/Holding";
import Onboarding from "./js/routes/Onboarding";

//Register state management
import store from "./js/store";
import {useStore} from "./js/store/store";
import NotFound from "./js/routes/NotFound";



store();


const App = () => {

    const [, dispatch] = useStore(false);

    useEffect(()=>{

        const recordScroll = e => {
            const scroll = document.documentElement.scrollTop
            const scrollAdj = scroll < 0 ? 0 : scroll;
            dispatch('WINDOW_SCROLL', scrollAdj);
        }

        window.addEventListener("scroll", recordScroll);

        //Remove on dismount
        return () => {
            window.removeEventListener("scroll", recordScroll)
        }
    },[dispatch])

    return (
        <Suspense fallback={<Spinner/>}>
            <Routes>

                <Route path="/" element={<Holding/> } />
                <Route path="/onboarding" element={<Onboarding/> } />

                <Route path="/search" element={<Search/> } />
                <Route path="/profile/:profileURL" element={<Profile/> } />

                <Route path="*" element={<NotFound/> } />
            </Routes>
        </Suspense>
    )

}

export default App;
