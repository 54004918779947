import axios from "axios";

const baseURL = 'https://my.westwales.media/api/';

export const getData = (url, controller, params = null, base_url = baseURL) => {
    return new Promise((success, reject) => {

        if(baseURL) {
            axios.defaults.baseURL = base_url;
        }

        const api_key = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'T5903773211668343' : 'vnq8afbamaatayyz'

        let attempts = 5

        //Create function to get results
        const getResults = () => {

            axios.get(url + api_key, {
                signal: controller.signal,
                params: params
            })
                .then(results => {
                    // Fire success Promise function
                    success(results.data)
                })
                .catch(error => {

                    //Stop if already tried 5 times and error
                    if(attempts > 5) {
                        // Fire reject Promise function
                        reject()
                    } else {
                        // Stop timeout if aborted
                        if(controller.signal.aborted) return
                        setTimeout(()=>{
                            getResults()
                        },3000)
                    }

                    //Send error to console
                    console.log(error)

                    // Increase attempts
                    attempts++
                })
        }

        // Fire function for the first time
        getResults()




    })
}

