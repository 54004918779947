import classes from "./Heading.module.scss"
import React, {useState} from "react";
import CheckOnScreen from "./CheckOnScreen";

const Heading = props => {

    const [onScreen, setOnScreen] = useState(false);

    const headingClasses = [classes.heading]

    if(onScreen) {
        headingClasses.push(classes.onScreen)
    }


    return (

        <div className={headingClasses.join(" ")}>
            <CheckOnScreen OnPage={()=>setOnScreen(true)}>
                {props.children}
            </CheckOnScreen>
        </div>


    )
}

export default Heading
