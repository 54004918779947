import Utils from "../../Utils";
import SearchFilterContainer from "./SearchFilterContainer";
import SearchFilterList from "./SearchFilterList";


const SearchFilterServices = props => {

    const changeService = (item, add=true) => {
        props.setSearchPending(true)
        Utils.changeListData(item, Utils.servicesList, 'services', props.setSearchData, add)
    }

    const selectedText = Utils.arrayToString(props.data, ' & ')
    const noSelection = props.data.length === 0


    return (
        <SearchFilterContainer
            defaultTitle="Any service"
            popUpTitle="Services"
            selectedText={selectedText}
            noSelection={noSelection}
            toggleOpen={props.toggleOpen}
            open={props.open}
            clearAll={()=>changeService('_clearAll')}
            //filtersLoading={props.filtersLoading}
        >
            <SearchFilterList
                popUpContent={Utils.servicesList}
                onChange={changeService}
                data={props.data}
            />
        </SearchFilterContainer>
    )
}

export default SearchFilterServices
