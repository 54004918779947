import classess from "./Result.module.scss"

const Result = props => {

    const classList = [classess.div]

    if(props.type) {
        classList.push(classess[props.type])
    }

    return (
        <div className={classList.join(" ")}>
            {props.children}
        </div>
    )
}

export default Result;
