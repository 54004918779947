import classes from "./Input.module.scss"

const Input = props => {

    const classList = [classes.div]

    if(props.bold) {
        classList.push(classes.bold)
    }

    return (
        <div className={classList.join(" ")}>
            {props.children}
        </div>
    )
}

export default Input
