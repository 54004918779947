import React, {useEffect, useState} from "react";
import {getData} from "../api/Helpers";
import MainLayout from "../layout/MainLayout";
import Container from "../layout/Container";
import OnboardingForm from "../layout/OnboardingForm";
import Spinner from "../components/Spinner";
import Result from "../layout/Result";
import Heading from "../components/Heading";
import AnimateIn from "../components/AnimateIn";

import errorImg from "../../imgs/error.png"
import swirl from "../../imgs/swirl.svg"

const Onboarding = () => {

    // Make sure at top of page
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])


    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [sending, setSending] = useState(false)
    const [systemError, setSystemError] = useState(false)
    const [sent, setSent] = useState(false)


    const submit = () => {

        setSending(true);

        // Create full name
        let fname = data.app_name.substring(0, data.app_name.indexOf(' '));
        let lname = data.app_name.substring(data.app_name.indexOf(' ') + 1);

        if(!fname) {
            fname = lname
            lname = ''
        }

        const getParams = {
            ...data,
            fname,
            lname
        }

        //console.log(getParams)

        const controller = new AbortController();
        getData('/post-onboarding/', controller, getParams)
            .then(res => dealWithRes(res))
            .catch(err => {
                console.log(err)
                setSystemError(true)
            })
            .finally(() => setSending(false))

    }


    const dealWithRes = (res) => {

        //console.log(res)

        window.scrollTo(0, 0);

        if(res.errors.length > 0) {
            setErrors(res.errors)
        } else if (res.sent) {
            setSent(true)
            setErrors([])
            // If C1 false already pending
            if(res.c1) {
                sendLead()
            }
        } else {
            setSystemError(true)
            // Reset errors
            setErrors([])
        }
    }

    const sendLead = () => {
        window.dataLayer.push({
            'event': 'lead',
        });
    }



    return (
        <MainLayout noBorder={true}>
            <Container>

                {sent && <Result type="success">

                    <AnimateIn type="spinIn">
                        <img src={swirl} alt="" width="216" height="200"/>
                    </AnimateIn>

                    <Heading>
                        <h2>Thank you</h2>
                    </Heading>

                    <AnimateIn type="zoomOut" delay="300">
                        <h3>Thanks for signing up to West Wales Media today.</h3>
                    </AnimateIn>


                    <AnimateIn type="zoomOut" delay="600">

                        <p>Please check your email inbox to verify your email address and we can add you to our mailing list.</p>
                        <p>If you asked to join West Wales Media as a featured business, we’ll be in touch soon to set up your profile.</p>
                        <p>If you registered for launch updates when we launch, just hold tight, we’ll be in touch soon.</p>
                        <p>Any problems or questions, please get in touch at <a href="mailto:hello@westwales.media" target="_blank" rel="noreferrer" >hello@westwales.media</a></p>
                    </AnimateIn>


                </Result>}

                {systemError && <Result type="error">

                    <AnimateIn type="zoomOut" delay="0">
                        <img src={errorImg} alt="" height="200" width="200"/>
                    </AnimateIn>

                    <Heading>
                        <h2>Oh no!</h2>
                    </Heading>

                    <AnimateIn type="zoomOut" delay="300">
                        <h3>Either you've already signed up or something unknown has gone wrong.</h3>
                        <p>Please email us at <a href="mailto:hello@westwales.media" target="_blank" rel="noreferrer" >hello@westwales.media</a>  so we can help sign you up.</p>
                    </AnimateIn>


                </Result>}

                {!systemError && !sent && <OnboardingForm
                    data={data}
                    errors={errors}
                    setData={setData}
                    submit={submit}
                />}

                {sending && <Spinner fixed outer/>}
            </Container>
        </MainLayout>
    )

}

export default Onboarding
