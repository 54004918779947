import React, {useState} from "react";
import ProfileSummary from "../components/ProfileSummary";
import Container from "./Container";
import classes from "./SearchResults.module.scss";

const SearchResults = props => {

    const [maskStyles, setMaskStyles] = useState()

    const animate = styles => {
        setMaskStyles(styles)
    }

    return (
        <Container>
            {Object.keys(props.results).length === 0 && !props.loading && <div className={classes.noResults}>No results</div>}
            <div className={classes.main}>
                {maskStyles && <div className={classes.mask} style={maskStyles}/>}
                {Object.entries(props.results).map(([key, profile]) => <div className={classes.item} key={key}><ProfileSummary data={profile} animate={animate}/></div>)}
            </div>
        </Container>
    )
}

export default SearchResults;
