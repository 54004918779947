import { useState, useEffect } from 'react';

let globalState = {};
let listeners = [];
let actions = {};

export const useStore = (shouldListen = true) => {

    const [ ,setState] = useState(globalState);

    const dispatch = (action, payload) => {
        const newState = actions[action](globalState, payload);
        globalState = {
            ...globalState,
            ...newState
        }

        //Push update to all components
        listeners.forEach(listener => listener(globalState))
    }

    useEffect(() => {

        if(shouldListen){
            listeners.push(setState);
        }

        //Unmount when done
        return () => {
            if(shouldListen){
                listeners = listeners.filter(listener => listener !== setState);
            }

        }
    }, [shouldListen]);


    return [globalState, dispatch]

};

export const initStore = (userActions, initState) => {
    if(initState){
        globalState = {
            ...globalState,
            ...initState
        }
    }

    actions = {
        ...actions,
        ...userActions
    }
}
