import Heading from "../components/Heading";
import Container from "./Container";
import Button from "../components/Button";
import AnimateIn from "../components/AnimateIn";

import classes from "./JoinUs.module.scss";

const JoinUs = props => {

    return (
        <div className={classes.div}>
            <Container>

                <Heading>
                    <h2> Interested?<br/> Join us <span>it's free!</span></h2>
                </Heading>
                <AnimateIn type="fadeUp" delay="300">
                    <p>Sign up here to be one of the first to join West Wales Media or receive an invite when we're ready to launch.</p>
                </AnimateIn>
                <AnimateIn type="fadeUp" delay="600">
                    <Button
                        url="/onboarding"
                        pageChange
                        btnStyle="primary"
                        big
                        callback={props.changePage}
                        arrow>Keep me updated</Button>
                </AnimateIn>
            </Container>
        </div>

    )

}

export default JoinUs;
