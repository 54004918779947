import classes from "./Intro.module.scss"
import Container from "./Container";
import AnimateIn from "../components/AnimateIn";
import Heading from "../components/Heading";
import Button from "../components/Button";

import swirl from "../../imgs/swirl.svg"
import deeper from "../../imgs/dive-deeper.svg"

const Intro = props => {


    return (
        <div className={classes.intro}>

            <Container>


                <div className={classes.introText}>
                    <AnimateIn type="fadeUp" delay="500">
                        <p>A new creative marketing network and directory for Pembrokeshire, Carmarthenshire and Ceredigion.</p>
                    </AnimateIn>
                </div>

                <div className={classes.more}>

                    <div className={classes.img}>
                        <AnimateIn type="spinIn">
                            <img src={swirl} alt=""/>
                        </AnimateIn>

                    </div>

                    <div className={classes.text}>
                        <Heading>
                            <h2>Innovative. Creative. Talented.</h2>
                        </Heading>
                        <AnimateIn type="zoomOut" delay={300}>
                            <p>We think the people of West Wales are innovative, creative and talented. West Wales Media exists to showcase this talent and build a community of creatives. We aim to become the best place to find marketing and creative services across the region.</p>
                        </AnimateIn>

                        <AnimateIn type="zoomOut" delay={500}>
                            <Button
                                url="/onboarding"
                                pageChange
                                btnStyle="primary"
                                big
                                callback={props.changePage}
                                arrow>Keep me updated</Button>
                        </AnimateIn>
                    </div>


                </div>

                <div className={classes.dive}>
                    <AnimateIn type="zoomOut" delay={500}>
                        <img src={deeper} alt="Dive deeper"/>
                    </AnimateIn>
                </div>



            </Container>

        </div>
    )

}

export default Intro;
