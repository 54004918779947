import React, {useState} from "react";

import MainLayout from "../layout/MainLayout";
import Hero from "../layout/Hero";
import Intro from "../layout/Intro";
import Deeper from "../layout/Deeper";
import Slideshow from "../layout/Slideshow";
import JoinUs from "../layout/JoinUs";
import PageChange from "../components/PageChange";


const Holding = () => {

    const [change, setChange] = useState(false)

    const changePage = () => {
        setChange(true)
    }



    return (
        <MainLayout noBorder={true}>
            {change && <PageChange/>}
            <Hero changePage={changePage}/>
            <Intro changePage={changePage}/>
            <Deeper/>
            <Slideshow/>
            <JoinUs changePage={changePage}/>


        </MainLayout>
    )
}

export default Holding
