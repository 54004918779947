import Utils from "../../Utils";
import SearchFilterContainer from "./SearchFilterContainer";
import SearchFilterList from "./SearchFilterList";


const SearchFilterType = props => {

    const changeType = (item, add=true) => {
        props.setSearchPending(true)
        Utils.changeListData(item, Utils.typeList, 'type', props.setSearchData, add)
    }

    const selectedText = Utils.arrayToString(props.data, ' or ')
    const noSelection = props.data.length === Utils.typeList.length || props.data.length === 0


    return (
        <SearchFilterContainer
            defaultTitle="All business types"
            popUpTitle="Business types"
            selectedText={selectedText}
            noSelection={noSelection}
            toggleOpen={props.toggleOpen}
            open={props.open}
            clearAll={()=>changeType('_addAll')}
            //filtersLoading={props.filtersLoading}
        >
            <SearchFilterList
                popUpContent={Utils.typeList}
                onChange={changeType}
                data={props.data}
            />
        </SearchFilterContainer>
    )
}

export default SearchFilterType
