import {initStore} from "./store";

const windowScrollStore = () => {

    const actions = {
        WINDOW_SCROLL: (curState, status) => {


            //If difference is too much return current state - blocks over scrolling issues
            const scrollingDown = (curState.windowScroll - status > 5 || curState.windowScroll - status < -5) ?
                curState.windowScrollDown :
                curState.windowScroll <= status

            return {
                windowScroll: status,
                windowScrollDown: status > 0 ? scrollingDown : false
            }
        }
    }

    initStore(actions, {windowScroll:0, windowScrollDown: false});

}

export default windowScrollStore;
