import React, {useEffect, useState} from "react";
import MainLayout from "../layout/MainLayout";
import ProfilePage from "../layout/ProfilePage";
import {useParams} from "react-router-dom";
import {getData} from "../api/Helpers";
import Spinner from "../components/Spinner";
import Utils from "../Utils";

const Profile = () => {


    let params = useParams();

    const [result, setResult] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const getParams = {
            url_title: params.profileURL
        }

        const controller = new AbortController();
        getData('/get-profile/', controller, getParams)
            .then(result => setResult(result))
            .catch(() => setError(true))
            .finally(() => setLoading(false))
        return () => controller.abort()
    },[params.profileURL])


    useEffect(()=>{
        if(!result) return
        document.title = result.seo_title || 'West Wales Media | ' + result.name
        const description = result.seo_description || result.description_short
        Utils.SetDescription(description)
    }, [result])



    return (
        <MainLayout>
            {result && <ProfilePage result={result}/>}
            {loading && <Spinner/>}
            {error && <p>Something went wrong</p>}
        </MainLayout>
    )
}

export default Profile;
