import React from "react";
import classes from "./MainLayout.module.scss"
import Header from "../components/Header";

const MainLayout = props => (
    <React.Fragment>
        <Header noBorder={props.noBorder}/>
        <div className={classes.div}>
            {props.children}
        </div>

    </React.Fragment>
)

export default MainLayout;
