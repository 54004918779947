import classes from "./ButtonToggle.module.scss"
import tick from "../../imgs/check.svg"

const ButtonToggle = props => {

    const classList = [classes.div];

    if(props.selected){
        classList.push(classes.selected)
    }

    if(props.lrg) {
        classList.push(classes.lrg)
    }

    const callback = e => {
        e.preventDefault();
        if(props.callback){
            props.callback();
        }
    }


    return (
        <div className={classList.join(" ")} onClick={callback}>
            {props.children}
            <img src={tick} alt="selected"/>
        </div>
    )
}

export default ButtonToggle
