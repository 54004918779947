import classes from "./SearchFilterLocationPopup.module.scss"
import Button from "../Button";
import Input from "../Input";
import Utils from "../../Utils";

const SearchFilterLocationPopup = props => {

    const myLocationClasses = [classes.myLocation];

    const radiusChanged = e => {
        props.setSearchPending(true)
        props.onChange(prevState => ({
            ...prevState,
            radius: e.target.value
        }))
    }

    const getMyLocation = () => {
        Utils.getMyLocation(
            props.setFiltersLoading,
            props.onChange,
            props.setSearchPending
        )
    }

    const inputChange = e => {
        props.setSearchPending(true)
        props.onChange(prevState =>({
            ...prevState,
            locationName: e.target.value,
            lat: undefined,
            lng: undefined
        }))
    }

    if(props.data.locationName){
        myLocationClasses.push(classes.myLocationClosed)
    }



    return (
        <div className={classes.location}>

            <Input>
                <input type="text" value={props.data.locationName || ""} onChange={inputChange}/>
            </Input>

            <Input>
                <select onChange={radiusChanged} value={props.data.radius || Utils.defaultRadius}>
                    <option value="0">+ 0 miles</option>
                    <option value="1">+ 1 mile</option>
                    <option value="5">+ 5 miles</option>
                    <option value="10">+ 10 miles</option>
                    <option value="15">+ 15 miles</option>
                    <option value="20">+ 20 miles</option>
                    <option value="50">+ 50 miles</option>
                </select>
            </Input>




            <div className={myLocationClasses.join(" ")}>
                <hr/>
                <Button callback={getMyLocation} w100 btnStyle="primary" disabled={props.data.locationName === "My location"}>Use my location</Button>
            </div>


        </div>
    )
}

export default SearchFilterLocationPopup
