import React from "react";
import classes from "./Spinner.module.scss"

const Spinner = props => {

    const classList = [classes.loader]

    if(props.fixed){
        classList.push(classes.fixed)
    }


    return (
        <React.Fragment>
            <div className={classList.join(" ")}/>
            {props.outer && <div className={classes.outer}/>}
        </React.Fragment>

    )
}

export default Spinner;
