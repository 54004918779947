import classes from "./Button.module.scss"
import {useNavigate} from "react-router-dom";
import Utils from "../Utils";
import img from "../../imgs/btn-arrow.svg"

const Button = props  => {

    const classList = [classes.a]
    const navigate = useNavigate()

    if(props.w100){
        classList.push(classes.w100)
    }

    if(props.disabled) {
        classList.push(classes.disabled)
    }

    if(props.btnStyle) {
        classList.push(classes[props.btnStyle])
    }

    if(props.big) {
        classList.push(classes.big)
    }

    if(props.arrow) {
        classList.push(classes.arrow)
    }

    const linkClicked = e => {

        e.preventDefault()

        //Run any functions
        if(props.callback && !props.disabled){
            props.callback()
        }

        if(props.pageChange && props.url){
            setTimeout(()=>{
                navigate(props.url)
            }, Utils.PageLoadTime)
        }

    }

    return <a href={props.url} className={classList.join(" ")} onClick={linkClicked} aria-disabled={props.disabled}>
        {props.children}
        {props.arrow && <img src={img} alt="arrow"/>}
    </a>
}

export default Button;
