import React from "react";
import Container from "./Container";
import classes from "./ProfilePage.module.scss"
import logoHolder from "../../imgs/profile-logo.svg";
import Badge from "../components/Badge";
import star from "../../imgs/star.svg";
import facebook from "../../imgs/facebook.svg";
import twitter from "../../imgs/twitter.svg";
import behance from "../../imgs/behance.svg";
import ProfileStories from "../components/ProfileStories";
import Utils from "../Utils";

const ProfilePage = props => {

    const profileLogo = props.result.logo ? props.result.logo : logoHolder

    return (
        <Container>

            <section className={classes.section}>
                <div className={[classes.box, classes.header].join(" ")}>
                    <div className={classes.logo}>
                        <img src={profileLogo} alt={props.result.name}/>
                    </div>

                    <div className={classes.main}>
                        <div className={classes.title}>
                            <h1>{props.result.name}</h1>

                            <Badge square>{props.result.type}</Badge>
                        </div>

                        {props.result.founder && <div className={classes.founder}>
                            <img src={star} alt="founder" />
                            <span>Founding member</span>
                        </div>}
                        <p>{props.result.description_short}</p>
                    </div>
                    <div className={classes.detail}>
                        <ul className={classes.contact}>
                            <li><span>Location</span><span>{props.result.location || ""}</span></li>
                            <li><span>Website</span><span>{props.result.website ? Utils.createUrlLink(props.result.website) : ""}</span></li>
                            <li><span>Phone</span><span>{props.result.phone || ""}</span></li>
                            <li><span>Email</span><span>{props.result.email ? Utils.createEmailLink(props.result.email) : ""}</span></li>
                        </ul>
                        <ul className={classes.social}>
                            {props.result.facebook && <li><img src={facebook} alt="facebook"/> </li>}
                            {props.result.instagram && <li>Instagram</li>}
                            {props.result.tiktok && <li>tiktok</li>}
                            {props.result.linkedin && <li>linkedin</li>}
                            {props.result.vimeo && <li>vimeo</li>}
                            {props.result.youtube && <li>youtube</li>}
                            {props.result.twitter && <li><img src={twitter} alt="twitter"/> </li>}
                            {props.result.behance && <li><img src={behance} alt="facebook"/> </li>}
                        </ul>
                    </div>
                </div>
            </section>

            <section className={classes.section}>
                <div className={[classes.box, classes.services].join(" ")}>
                    <h2>{props.result.name}'s services</h2>
                    <ul>
                        {props.result.services.length > 0 && props.result.services.map((el,i) => <li key={i}><Badge>{el}</Badge></li>)}
                    </ul>
                </div>
            </section>

            <section className={[classes.section, classes.body].join(" ")}>

                <div>
                    <div className={[classes.box, classes.about].join(" ")}>
                        <h2>About {props.result.name}</h2>
                        <div dangerouslySetInnerHTML={{__html:props.result.description_long}} />
                    </div>
                    <div className={classes.box}>
                        <h2>{props.result.name}'s gallery</h2>
                    </div>
                </div>

                <div>
                    <div className={classes.box}>
                        <h2>{props.result.name}'s stories</h2>
                        <ProfileStories author={props.result.author_id}/>
                    </div>
                </div>



            </section>

        </Container>
    )


}

export default ProfilePage
