import classes from "./Deeper.module.scss"

import deeper1 from "../../imgs/deeper1.svg"
import deeper2 from "../../imgs/deeper2.svg"
import deeper3 from "../../imgs/deeper3.svg"
import deeper4 from "../../imgs/deeper4.svg"
import AnimateIn from "../components/AnimateIn";

const Deeper = () => {

    return (
        <div className={classes.deeper}>
            <div className={classes.deeper1}>
                <AnimateIn type="deeper">
                    <img src={deeper1} alt=""/>
                </AnimateIn>
            </div>
            <div className={classes.deeper2}>
                <AnimateIn type="deeper" delay={300}>
                    <img src={deeper2} alt=""/>
                </AnimateIn>
            </div>
            <div className={classes.deeper3}>
                <AnimateIn type="deeper" delay={500}>
                    <img src={deeper3} alt=""/>
                </AnimateIn>
            </div>
            <div className={classes.deeper4}>
                <AnimateIn type="deeper" delay={700}>
                    <img src={deeper4} alt=""/>
                </AnimateIn>
            </div>
        </div>
    )
}

export default Deeper;
