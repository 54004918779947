class Utils {


    static PageLoadTime = 700;

    static typeList = ['Agency', 'Consultant', 'Freelancer'];

    static servicesList = [
        'Marketing',
        'Web design',
        'Website management',
        'Branding',
        'Graphic design',
        'Illustration',
        'Apps',
        'Web development',
        'React',
        'Wordpress',
        'Dashboards',
        'eCommerce',
        'iOS',
        'Photography',
        'Videography',
        'Drone Photography',
        'Social Media',
        'Animation',
        'Podcast Production',
        'Art Direction',
        'Sound Engineer',
        'Lighting Engineer',
        'Styling',
        'Copywriting',
        'SEO',
        'Content Marketing'
    ];

    static defaultRadius = 15;

    static myLocationString = "My location";


    // static getRandomInt(min, max) {
    //     min = Math.ceil(min);
    //     max = Math.floor(max);
    //     return Math.floor(Math.random() * (max - min) + min);
    // }

    static getMyLocation = (searching, onSuccess, tertiary = null) => {

        searching(true)

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                searching(false)
                onSuccess(prevState=>({
                    ...prevState,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    locationName: Utils.myLocationString
                }))
                if(tertiary){
                    tertiary(true)
                }
            });
        } else {
            searching(false)
            alert("Sorry, geolocation is not supported by this browser. Please enter location manually")
        }

    }

    static arrayToString = (array, sep = ', ') => array.reduce((total, item, i) => {
        const showSep = i > 0 ? sep : ''
        return total + showSep + item
    }, '')


    static cropString = (string, limit) => {
        let newString = string

        if(newString.length > limit){
            newString = newString.slice(0, limit) + '...'
        }

        return newString
    }


    static SetDescription = description => {
        document.querySelector('meta[name="description"]').setAttribute("content", description);
    }

    static changeListData = (item, list, listName, stateChanger, add=true) => {

        if(item === '_clearAll' || item === '_addAll'){
            stateChanger(prevState => ({
                ...prevState,
                [listName]: item === '_addAll' ? list : []
            }))
            //If clear all called block further code
            return
        }

        stateChanger(prevState => {
            let newArray = []

            if(add){
                newArray = [...prevState[listName], item]
            } else {
                newArray = prevState[listName].filter(el=>el!==item)
            }

            return ({
                ...prevState,
                [listName]: newArray
            })
        })
    }


    static createUrlLink = url => {
        // remove https and trailing /
        const text = url.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '');
        return <a href={url} target="_blank" rel="noreferrer">{text}</a>
    }

    static createEmailLink = email => <a href={'mailto:'+ email}>{email}</a>



}
export default Utils
