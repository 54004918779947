import classes from "./Header.module.scss";
import Container from "../layout/Container";
import logoWhite from "../../imgs/logo-white.svg"
import logo from "../../imgs/logo.svg"
import {Link} from "react-router-dom";

const Header = props => {

    //console.log(props.noBorder)

    const headerClasses = [classes.main]

    if(!props.noBorder) {
        headerClasses.push(classes.border)
    }

    const logoImg = props.noBorder ? logoWhite : logo

    return (
        <div className={headerClasses.join(" ")}>
            <Container>
                <div className={classes.logo}>
                    <Link to="/">
                        <img src={logoImg} alt="West Wales Media"/>
                    </Link>

                </div>

            </Container>
        </div>
    )
}
export default Header;
