import React, {useEffect, useState} from "react";
import MainLayout from "../layout/MainLayout";
import SearchResults from "../layout/SearchResults";
import {getData} from "../api/Helpers";
import CheckOnScreen from "../components/CheckOnScreen";
import Spinner from "../components/Spinner";
import SearchBox from "../layout/SearchBox";
import Utils from "../Utils";

const Search = () => {

    // Set SEO
    useEffect(()=>{
        document.title = 'West Wales Media | Search'
        const description = "Start your search"
        Utils.SetDescription(description)
    }, [])

    const itemsPerLoad = 12;

    const [results, setResults] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadedAll, setLoadedAll] = useState(false);
    const [searchPending, setSearchPending] = useState(false)
    const [filtersOpen, setFiltersOpen] = useState({
        type: false,
        location: false,
        services: false
    })
    const [filtersLoading, setFiltersLoading] = useState({
        type: false,
        location: false,
        services: false
    })
    const [params, setParams] = useState({
        offset: 0,
        limit: itemsPerLoad
    })
    const [searchData, setSearchData] = useState({
        type: Utils.typeList,
        services: [],
        radius: Utils.defaultRadius
    });
    const [searching, setSearching] = useState(false)


    useEffect(() => {
        const controller = new AbortController();
        getData('/search-profiles/', controller, params)
            .then(result => setResults(prevState => {
                // If empty array stop trying to load more
                if(Object.keys(result).length === 0) {
                    setLoadedAll(true)
                }

                //console.log(result)

                // Return page data plus previous data
                return {
                    ...prevState,
                    ...result
                }
            }))
            .catch(() => setError(true))
            .finally(() => {
                setLoading(false)
                setSearching(false)
            })
        return () => controller.abort()
    },[params])

    const loadMore = () => {
        if(loading || error || loadedAll) return

        setLoading(true)
        setParams(prevState => ({
            ...prevState,
            offset: prevState.offset + prevState.limit,
            // offset: 0,
            // test: 1
        }))
    }


    const toggleFilter = (filter, open = true) => {
        setFiltersOpen(prevState => ({
            ...prevState,
            [filter]: open,
        }))
    }


    const filterLoadingFunc = (filter , loading) => {
        setFiltersLoading(prevState => ({
            ...prevState,
            [filter]: loading
        }))
    }



    const runSearch = () => {

        setLoading(true)

        // No checks on Type or Services so close them
        setFiltersOpen(prevState => ({
            ...prevState,
            type: false,
            services: false
        }))


        // If location still open or set need to check
        if(filtersOpen.location || searchData.locationName){

            // If no name close filter and carry on
            if(!searchData.locationName) {
                toggleFilter('location', false)
                return
            }

            // Just in case user type my location...
            if(searchData.locationName === Utils.myLocationString){

                if((!searchData.lat || !searchData.lng)){
                    Utils.getMyLocation(
                        bool => {
                            filterLoadingFunc('location', bool)
                            toggleFilter('location', bool)
                            //setOpen(bool)
                        },
                        setSearchData
                    )
                } else {
                    toggleFilter('location', false)
                }

                return
            }

            //Else check location input with Google
            filterLoadingFunc('location', true)

            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({
                address: searchData.locationName
            }).then(result => {
                //console.log(result, result.results[0].geometry.location.lat(), result.results[0].geometry.location.lng())
                setSearchData(prevState => ({
                    ...prevState,
                    lat: result.results[0].geometry.location.lat(),
                    lng: result.results[0].geometry.location.lng()
                }))
                console.log(result.results[0])
                toggleFilter('location', false)
            }).catch(error => {
                //Clear data as nothing found
                setResults({})
                // setSearchPending(false)
                setLoading(false)
                setSearching(false)
                alert("Sorry, we couldn't find that location. Please try again")
                console.log(error)
            }).finally(()=>{
                filterLoadingFunc('location', false)
                //setLoading(false)
            })

            return
        }


        // If filters not open trigger search
        setSearching(true)

    }

    // This is used when filters are open and search is pressed. Search will wait for all filters to close
    useEffect(()=>{
        if(
            !filtersOpen.location &&
            !filtersOpen.type &&
            !filtersOpen.services &&
            searchPending &&
            loading &&
            !filtersLoading.location
        ){
            setSearching(true)
        }
    }, [filtersOpen, searchPending, loading, filtersLoading])

    // This triggers the search
    useEffect(()=>{
        if(searching){

            //console.log(searchData)

            // Reset flags
            setLoadedAll(false)
            setSearchPending(false)

            //console.log(searchData)

            // Reset search counts and add search data
            setResults({})
            setParams({
                offset: 0,
                limit: itemsPerLoad,
                ...searchData
            })
        }
    },[searching, searchData])

    return (
        <MainLayout>
            <SearchBox
                searchData={searchData}
                setSearchData={setSearchData}
                runSearch={runSearch}
                loading={loading}
                searchPending={searchPending}
                setSearchPending={setSearchPending}
                setFiltersOpen={setFiltersOpen}
                filtersOpen={filtersOpen}
                setFiltersLoading={filterLoadingFunc}
                filtersLoading={filtersLoading}

            />
            <SearchResults results={results} loading={loading}/>
            {error && <p>Something went wrong</p>}
            <CheckOnScreen OnPage={loadMore}>
                <div style={{height:"120px", position:"relative"}}>
                    {loading && <Spinner fixed={params.offset === 0}/>}
                </div>
            </CheckOnScreen>
        </MainLayout>
    )
}

export default Search;
