import Input from "../components/Input";
import Button from "../components/Button";
import React, {useEffect, useRef, useState} from "react";
import classes from "./OnboardingFormStage.module.scss"
import ButtonToggle from "../components/ButtonToggle";

const OnboardingFormStage = props => {

    const [showButton, setShowButton] = useState(false)
    const classList = [classes.div]
    const div = useRef()

    useEffect(()=> {

        let show = true

        props.stage.fields.filter(field => {
            if(!props.data[field.name]) show = false
            return false
        })

        setShowButton(show)

    }, [props.data, props.stage.fields])


    useEffect(() => {

            const keypressEvent = e => {
                if (e.key === 'Enter' && showButton && props.show) {
                    props.nextStep()
                }
            }

            // register above func
            window.addEventListener("keypress", keypressEvent)

            // Remove on dismount
            return () => window.removeEventListener("keypress", keypressEvent)

    },[showButton, props])

    useEffect(()=>{
        // Set parent form height to height of stage
        if(props.show){
            props.setFormHeight(div.current.clientHeight)
        }

    },[props])


    if(props.goingBack) {
        classList.push(classes.goingBack)

    }

    if(props.show){
        classList.push(classes.show)
    }

    if(props.hide){
        classList.push(classes.hide)
    }

    if(props.errors.length) {
        classList.push(classes.errors)
    }


    return (

        <div className={classList.join(" ")} ref={div}>
            {!props.errors.length && <h2>{props.stage.title}</h2>}

            {props.stage.fields.map((field, field_id) => {

                // Hide if errors found but not with this field
                if(props.errors.length && !props.errors.includes(field.name)) {
                    return ''
                }

                const errorMsg = props.errors.includes(field.name) ? <p className={classes.errorMsg}>Please enter a valid {field.name}</p> : null

                switch (field.type) {
                    case 'text':
                    case 'email':
                        return <div key={field_id}>
                            {errorMsg}
                            <Input bold>
                                <input
                                    type={field.type}
                                    id={field.name}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    value={props.data[field.name] || ''} // Always set an empty string if undefined
                                    onChange={props.updateField}
                                />
                            </Input>
                        </div>

                    case 'multi':
                        return <div key={field_id}>
                            {errorMsg}
                            {field.options.map((option, i) => {
                                return <div key={i}>
                                    <ButtonToggle
                                        lrg
                                        callback={()=>props.updateMulti(field.name, option)}
                                        selected={option === props.data[field.name]}
                                    >
                                        {option}
                                    </ButtonToggle>
                                </div>
                            })}
                        </div>

                    default:
                        return <div key={field_id}>???</div>
                }



            })}

            {!props.errors.length && <Button
                btnStyle="primary"
                w100
                arrow
                disabled={!showButton}
                callback={props.nextStep}
            >{props.stage.button}</Button>}

            {props.stage_id > 1 && !props.errors.length && <p onClick={props.prevStep} className={classes.prev}>&#8592; back</p>}
        </div>

    )
}

export default OnboardingFormStage
