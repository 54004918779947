import AnimateIn from "../components/AnimateIn";
import errorImg from "../../imgs/error.png";
import Heading from "../components/Heading";
import Result from "../layout/Result";
import React from "react";
import Container from "../layout/Container";
import MainLayout from "../layout/MainLayout";
import {Link} from "react-router-dom";

const NotFound = () => (
    <MainLayout noBorder={true}>
        <Container>
            <Result type="error">

                <AnimateIn type="zoomOut" delay="0">
                    <img src={errorImg} alt="" height="200" width="200"/>
                </AnimateIn>

                <Heading>
                    <h2>Oh no!</h2>
                </Heading>

                <AnimateIn type="zoomOut" delay="300">
                    <h3>We couldn't find the page you're looking for</h3>
                    <p>Nothing else to do but <Link to="/">go home</Link></p>
                </AnimateIn>


            </Result>
        </Container>

    </MainLayout>
)

export default NotFound
